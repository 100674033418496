import '@styles/group.sass'
import {isInViewport} from "./utils/is-in-viewport";
import tippy, {followCursor} from "tippy.js";
import 'tippy.js/dist/tippy.css';
import {createApp} from "petite-vue";

(() => {
    const guide = document.querySelector<HTMLElement>('[data-guide]')
    if (!guide) return

    const isCompactView = () => window.innerWidth < 1280

    function GuideSection() {
        return {
            hero: '',
            setVisibleHero(e: Event) {
                if (isCompactView()) return

                const target = e.target as HTMLElement
                this.hero = target.dataset?.key ?? ''
            },
            setUpDropdownListeners(e: Event) {
                const detail = e.target as HTMLDetailsElement

                const handleOutsideClick = (ev: Event) => {
                    if (detail.contains(ev.target as HTMLElement)) return
                    detail.open = false
                }

                document[detail.open ? "addEventListener" : "removeEventListener"]('click', handleOutsideClick)
                if (!isCompactView() && !detail.open) detail.open = true
            }
        }
    }

    createApp({ GuideSection }).mount(guide)

    const toggleDetails = () => {
        guide.querySelectorAll('details')
            .forEach(detail => { detail.open = !isCompactView() })
    }

    toggleDetails()
    //window.addEventListener("resize", toggleDetails, { passive: true })
})();

(() => { // Count up numbers
    const animationDuration = 2400;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round( animationDuration / frameDuration );
    const easeOutQuad = (t: number) => t * ( 2 - t );

    const animateCountUp = (el: HTMLElement) => {
        if (el.classList.contains("is-counted"))
            return;

        el.classList.add("is-counted")
        el.innerHTML = el.innerHTML.replace(/([0-9]+)/g, '<span>$1</span>');
        let frame = 0;

        const number = el.querySelector('span')
        if (number.nextElementSibling?.nodeName === "SPAN") {
            number.innerHTML += number.nextElementSibling.innerHTML
            number.nextElementSibling.remove()
        }

        const countTo = parseInt( number.innerHTML, 10 );
        const counter = setInterval( () => {
            frame++;
            const progress = easeOutQuad( frame / totalFrames ),
                currentCount = Math.round( countTo * progress );

            if ( parseInt( number.innerHTML, 10 ) !== currentCount )
                number.innerHTML = currentCount.toLocaleString()

            frame === totalFrames && clearInterval( counter )
        }, frameDuration );
    };

    const runCountUp = () => {
        document.querySelectorAll<HTMLElement>('[data-group-number]')
            .forEach(el => isInViewport(el, 40) && animateCountUp(el));
    }

    runCountUp();
    window.addEventListener("scroll", runCountUp);
})();

(() => {
    const map = document.querySelector<HTMLElement>('[data-world-map]');
    if (!map) return

    const drawMapLines = () => {
        if (!isInViewport(map, 4)) return

        map.querySelectorAll<SVGPathElement>('path.is-line').forEach(path => {
            path.style.strokeDasharray = `${path.getTotalLength()}`
            path.style.strokeDashoffset = `${path.getTotalLength()}`
            setTimeout(() => path.classList.add('is-drawn'), Math.random() * (800 - 400) + 400)
        })

        window.removeEventListener("scroll", drawMapLines)
    }

    drawMapLines()
    window.addEventListener("scroll", drawMapLines)

    tippy('[data-area-name]', {
        content: (reference) => reference.getAttribute('data-area-name'),
        arrow: false,
        followCursor: true,
        plugins: [followCursor]
    })
})();
